module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/faviconflapmax.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d54d78ff6a10727af347ada82eddb3ca"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"flapmax-university-alliance","fetchLinks":[],"schemas":{"event":{"Main":{"uid":{"type":"UID","config":{"label":"Event UID","placeholder":"Enter UID"}},"publish_date":{"type":"Date","config":{"label":"Publish_Date","placeholder":"Enter Date"}},"event_category":{"type":"Text","config":{"label":"Event Category","placeholder":"Enter Category (Sprint, Hackathon, News, etc)"}},"header_image":{"type":"Image","config":{"constraint":{"width":870,"height":null},"thumbnails":[],"label":"Header Image"}},"event_title":{"type":"StructuredText","config":{"single":"heading1","label":"Event Title","placeholder":"Enter Title"}},"event_date":{"type":"Date","config":{"label":"Event_Date","placeholder":"Enter Date"}},"location":{"type":"Text","config":{"label":"Location","placeholder":"Enter Location (Address, virtual, etc)"}},"event_body":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"Event Body","placeholder":"Enter Body"}}}}},"lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"typePathsFilenamePrefix":"prismic-typepaths---gatsby-source-prismic-test-site"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
